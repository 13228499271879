import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SectionWhat from "./components/SectionWhat/SectionWhat";
import SectionWhy from "./components/SectionWhy/SectionWhy";
import SectionHow from "./components/SectionHow/SectionHow";
import SectionWho from "./components/SectionWho/SectionWho";
import SectionWhen from "./components/SectionWhen/SectionWhen";
import SectionWhere from "./components/SectionWhere/SectionWhere";
import ScrollToTop from "./components/scroll-top/ScrollToTop";
import DiscoverSolution from "./components/scroll-top/DiscoverSolution";
import SustainablePage from "./components/theme-v2/homePageV2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";
import HomePageV2 from "./components/theme-v2/homePageV2";
import SustainableConstruction from "./components/theme-v2/Sustainable-Construction/Sustainable-Construction.js";
import ConstructionChallenges from "./components/theme-v2/Construction-Challenges/ConstructionChallenges.js";
import RdcHomePage from "./components/theme-v2/Construction-Challenges/Responsible-Development-Concierge/rdc-home-page.js";
import SlcHomePage from "./components/theme-v2/Construction-Challenges/Sustainable-Living-Concierge/slc-home-page.js";
import NavbarNew from "./components/Navbar/NavbarNew.js";
import ReusableNavbar from "./components/Navbar/ReusableNavbar.js";
import ContactUs from "./components/ContactUsPage.jsx";
import DiscoverSolutionNew from "./DiscoverSolution.js";

const App = () => {
  return (
    <>
      {/* <Navbar /> */}
      {/* <ReusableNavbar /> */}
      <NavbarNew />

      <Routes>
        <Route path="/" element={<HomePageV2 />} />
        <Route
          path="/Sustainable-Construction"
          element={<SustainableConstruction />}
        />
        <Route
          path="/Construction-Challenges"
          element={<ConstructionChallenges />}
        />
        <Route
          path="/Responsible-Development-Concierge"
          element={<RdcHomePage />}
        />{" "}
        <Route path="/Sustainable-Living-Concierge" element={<SlcHomePage />} />{" "}
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
      {/* <DiscoverSolutionNew /> */}
    </>
  );
};

export default App;
