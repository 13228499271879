import React, { useState, useEffect } from "react";
import SlcHowWeDoItPhone from "./slc-HowWeDoItPhone.js";
import SlcHowWeDoItLaptop from "./slc-howWeDoItLaptop.js";

export default function SlcHowWeDoIt() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update when screen resizes
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{isMobile ? <SlcHowWeDoItPhone /> : <SlcHowWeDoItLaptop />}</>;
}
