import React from "react";

const Loader = ({ fadeOut }) => (
  <div
    className={`fixed inset-0 flex flex-col items-center justify-center transition-opacity duration-500 ${
      fadeOut ? "opacity-0 pointer-events-none" : "opacity-100"
    }`}
  >
    <video
      className="w-full h-full object-cover absolute"
      autoPlay
      muted
      playsInline
      disablePictureInPicture
      webkit-playsinline="true"
      loop
    >
      <source
        src="https://res.cloudinary.com/dreommqtd/video/upload/v1739254283/loader-video_4_iqlafp.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>

    {/* <div className="flex space-x-2 mt-4">
      <div className="w-4 h-4 bg-gray-800 rounded-full animate-bounce"></div>
      <div className="w-4 h-4 bg-gray-800 rounded-full animate-bounce delay-150"></div>
      <div className="w-4 h-4 bg-gray-800 rounded-full animate-bounce delay-300"></div>
    </div> */}
  </div>
);

export default Loader;
