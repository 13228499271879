import React from "react";
import RDcWhyDoYouNeedUs from "./rdc-why-page.js";
import RdcWhatWeDo from "./rdc-what-we-do.js";
import RdcHowWeDoIt from "./rdc-HowWeDoIt.js";
import WhyAndRole from "../../../whyAndRole/whyAndRole.js";
import "./rdc.css";
import { useNavigate } from "react-router-dom";
import ContactUs from "../../../ContactUsPage.jsx";
import StickyFooter from "../../stickyFooter.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const RdcHomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Back Button */}
      <div className="back-btn">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </button>
      </div>
      <RDcWhyDoYouNeedUs />
      <RdcWhatWeDo />
      <RdcHowWeDoIt />
      <div className="py-10">
        <WhyAndRole />
      </div>
      {/* <div className="py-0">
        <StickyFooter />
      </div> */}
    </>
  );
};

export default RdcHomePage;
