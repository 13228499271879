import React from "react";
import RDcWhyDoYouNeedUs from "../../../../assets/rdc-what-we-do-bg.jpg";

export default function RdcWhatWeDo() {
  return (
    <div className="flex flex-col md:flex-row items-center h-screen lg:justify-between sm:justify-between md:justify-between  bg-white p-6 md:p-16 relative ">
      {/* Background Image for Mobile (Dimmed) */}
      <div
        className="absolute inset-1 bg-cover bg-center bg-no-repeat md:bg-none opacity-30 md:opacity-100"
        style={{ backgroundImage: `url(${RDcWhyDoYouNeedUs})` }}
      ></div>{" "}
      {/* Left Section */}
      <div className="relative md:w-1/3 lg:text-left md:text-left sm:text-left space-y-4 z-10 ">
        <h1 className="text-3xl md:text-4xl font-bold text-black">
          What do we do?
        </h1>
        <p className="text-black sm:text-left md:text-left md:text-base ">
          We offer bespoke solutions that{" "}
          <strong>
            reduce costs, enhance efficiency, and elevate project value
          </strong>
          , all while ensuring your construction is built to last.
        </p>
        {/* <button className="mt-4 px-6 py-2 border border-black rounded-full hover:bg-black hover:text-white transition duration-300">
          Discover Solutions
        </button> */}
      </div>
      {/* Right Section */}
      <div className="relative w-full md:w-1/3 text-left lg:space-y-6 md:space-y-14 sm:space-y-14 z-10 py-10 ">
        {" "}
        <div>
          <h3 className="font-bold text-black text-lg">
            Quality Assurance Across Phases
          </h3>
          <p className="text-black text-sm md:text-base">
            From foundation to finishing, we ensure workmanship meets premium
            standards.
          </p>
        </div>
        <div>
          <h3 className="font-bold text-black text-lg">
            Strategic Waterproofing
          </h3>
          <p className="text-black text-sm md:text-base">
            Advanced solutions to protect structures from Goa’s coastal climate
            and high moisture levels.
          </p>
        </div>
        <div>
          <h3 className="font-bold text-black text-lg">
            Material Sourcing & Optimization
          </h3>
          <p className="text-black text-sm md:text-base">
            Identifying cost-effective, high-performance materials to balance
            budget and durability.
          </p>
        </div>
        <div>
          <h3 className="font-bold text-black text-lg">Cost Management</h3>
          <p className="text-black text-sm md:text-base">
            Proactively controlling expenses and preventing financial
            escalations without compromising quality.
          </p>
        </div>
        <div>
          <h3 className="font-bold text-black text-lg">
            Modern Construction Techniques
          </h3>
          <p className="text-black text-sm md:text-base">
            Helping you integrate the latest methodologies (including BIM) to
            improve efficiency and reduce errors.
          </p>
        </div>
      </div>
    </div>
  );
}
