import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, X, Search, User, ShoppingBag } from "lucide-react";
import logo from "../../assets/seges-logo-dark.png";
import "./Navbar.scss";

const NavbarNew = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md fixed w-full z-50">
      <div className="max-w-1xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center h-16 items-center">
          {/* <div className="justify-0">
            {" "
            <div className="font-bold">|||</div>
          </div> */}
          {/* Left Section - Logo */}
          <div className="text-1xl font-bold text-gray-900">
            <Link to="/">
              {" "}
              <img src={logo} alt="Logo" className="h-32 scale-[2.5] w-32" />
            </Link>
          </div>

          {/* Center Section - Mega Menu */}
          {/* <div className="hidden md:flex space-x-6 flex-1 justify-center">
            <div className="relative group">
              <Link
                to="/shop"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Shop
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/gift"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Gift
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/about"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                About Us
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/help"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Help
              </Link>
            </div>
          </div> */}

          {/* Right Section - Icons */}
          {/* <div className="hidden md:flex space-x-6 items-center">
            <div className="relative group">
              <Link
                to="/Sustainable-Construction"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Sustainable Construction{" "}
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/Construction-Challenges"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Construction Challenges
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/Responsible-Development-Concierge"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Responsible Development Concierge{" "}
              </Link>
            </div>
            <div className="relative group">
              <Link
                to="/Sustainable-Living-Concierge"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                Sustainable Living Concierge
              </Link>
            </div>{" "}
            <div className="relative group">
              <Link
                to="/ContactUs"
                className="text-gray-700 font-semibold hover:text-yellow-600"
              >
                ContactUs
              </Link>
            </div>
          </div> */}

          {/* Mobile Menu Button */}
          {/* <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700"
            >
              {isOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div> */}
          {/* <div className="justify-0">
            {" "}
            <div className="font-bold ">|||</div>
          </div> */}
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        // <div className="">
        <div
          className={`mobile-menu md:hidden sm:hidden absolute top-16 left-0 w-full bg-white shadow-md ${
            isOpen ? "open" : ""
          }`}
        >
          <div className="flex flex-col space-y-4 p-4">
            <Link
              to="/Sustainable-Construction"
              className="text-gray-700 hover:text-yellow-600"
              onClick={() => setIsOpen(false)}
            >
              Sustainable Construction
            </Link>
            <Link
              to="/Construction-Challenges"
              className="text-gray-700 hover:text-yellow-600"
              onClick={() => setIsOpen(false)}
            >
              Construction Challenges
            </Link>
            <Link
              to="/Responsible-Development-Concierge"
              className="text-gray-700 hover:text-yellow-600"
              onClick={() => setIsOpen(false)}
            >
              Responsible Development Concierge{" "}
            </Link>
            <Link
              to="/Sustainable-Living-Concierge"
              className="text-gray-700 hover:text-yellow-600"
              onClick={() => setIsOpen(false)}
            >
              Sustainable Living Concierge
            </Link>{" "}
            <Link
              to="/ContactUs"
              className="text-gray-700 hover:text-yellow-600"
              onClick={() => setIsOpen(false)}
            >
              ContactUs
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarNew;
