import React from "react";

export default function SlcWhyDoYouNeedUs() {
  return (
    <div className="relative bg-black text-white max-h-full rounded-t-3xl rounded-l-3xl">
      {/* Background Section */}
      <div className="absolute inset-0 rounded-t-3xl rounded-l-3xl">
        <video
          className="absolute inset-0 w-full h-full object-cover opacity-70 "
          autoPlay
          loop
          muted
          playsInline
          disablePictureInPicture
          webkit-playsinline="true"
        >
          <source
            src="https://res.cloudinary.com/dreommqtd/video/upload/v1739167668/Slc-_votrdp.mp4"
            type="video/mp4"
          />{" "}
        </video>
      </div>

      {/* Content Section */}
      <div className="relative z-1 flex items-end justify-end min-h-screen p-0 bg-[black]/30 md:bg-[black]/30">
        <div className="max-w-lg w-full">
          <h1 className="bg-transparent text-[47px] font-bold text-yellow-400 mb-0 p-8">
            WHY DO YOU NEED US?
          </h1>
          <p className=" lg:bg-[white] text-[white] md:text-[white] lg:text-[black] p-8 rounded-t-3xl rounded-l-3xl max-w-lg w-full text-lg leading-relaxed">
            {" "}
            Building a <span className="font-semibold">luxury home</span> is a
            deeply personal journey—but it can quickly turn into a frustrating
            experience when dealing with{" "}
            <span className="font-semibold">
              contractors, material delays, quality concerns, and hidden costs.
            </span>{" "}
            Architects design, engineers plan, but who ensures
            <span className="font-bold">
              {" "}
              your vision is built with excellence? We do.
            </span>
          </p>
          {/* <button className="mt-6 px-6 py-3 bg-black text-white rounded-full shadow-md hover:bg-gray-800">
            Discover Solutions
          </button> */}
        </div>
      </div>
    </div>
  );
}
