import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ConstructionChallenges = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Back Button */}
      <div className="back-btn">
        <button className="back-button " onClick={() => navigate(-1)}>
          <a className="text-white">
            <ArrowBackIcon /> Back
          </a>
        </button>
      </div>
      <div className="flex flex-col md:flex-row w-full h-screen relative overflow-hidden bg-cover bg-no-repeat bg-center">
        {/* Left Section */}
        <div
          onClick={() => navigate("/Responsible-Development-Concierge")}
          className="relative xl:w-1/2 sm:w-full md:w-full h-full cursor-pointer flex flex-col justify-center p-6 md:p-10 lg:rounded-3xl sm:rounded-1xl md:rounded-1xl border-[12px] border-yellow-500 overflow-hidden transition-transform duration-300 hover:scale-105 "
        >
          {/* Yellow Fade Overlay at the Top */}
          <div className="absolute top-0 left-0 w-full h-1/3s"></div>

          {/* Background Video */}
          <video
            className="absolute inset-0 w-full h-full object-cover opacity-80"
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            webkit-playsinline="true"
          >
            <source
              src="https://res.cloudinary.com/dreommqtd/video/upload/v1739277078/slc-bg-vd_2_olnqhb.mp4"
              type="video/mp4"
            />
          </video>

          {/* Centered Text Box */}
          <div className="relative z-10 p-4 bg-yellow-500 bg-opacity-90 lg:w-2/3 sm:w-2/3 md:w-2/3 rounded-xl text-center mx-auto shadow-lg ">
            <h1 className="text-lg md:text-xl font-bold px-4 py-0 rounded-xl text-black">
              Responsible Development Concierge
            </h1>
            <p className="text-sm md:text-base text-black mt-2">
              Helping Builders & Developers Overcome Challenges Across Every
              Phase of Construction
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div
          onClick={() => navigate("/Sustainable-Living-Concierge")}
          className="xl:w-1/2 sm:w-full md:w-full h-full relative cursor-pointer flex flex-col justify-center p-6 md:p-10 rounded-br-3xl transition-transform duration-300 hover:scale-105"
        >
          <video
            className="absolute inset-0 w-full h-full object-cover opacity-70"
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            webkit-playsinline="true"
          >
            <source
              src="https://res.cloudinary.com/dreommqtd/video/upload/v1739169299/Sustainable-Living-Concierge-bg-vd_skmyxn.mp4"
              type="video/mp4"
            />{" "}
          </video>
          <div className="relative z-10 p-4 bg-white bg-opacity-80 lg:h-42 sm:h-42 md:h-42 lg:w-2/3 sm:w-2/3 md:w-2/3 rounded-2xl  text-center mx-auto my-auto">
            <h1 className="text-lg md:text-xl font-bold  px-4 py-2 rounded-xl">
              Sustainable Living Concierge
            </h1>
            <p className="text-sm md:text-base text-gray-700 mt-2">
              Making Homebuilding Effortless for Luxury Homeowners
            </p>
          </div>
          {/* <div className="absolute bottom-4 right-4">
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-8 h-8 bg-white rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-4 h-4 text-black"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
            </svg>
          </a>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ConstructionChallenges;
