import React from "react";

export default function RDcWhyDoYouNeedUs() {
  return (
    <div className="relative bg-black text-white max-h-full rounded-t-3xl rounded-l-3xl mb-9 border-b-slate-950">
      {/* Background Section */}
      <div className="absolute inset-0 rounded-t-3xl rounded-l-3xl overflow-hidden">
        {/* Video */}
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          disablePictureInPicture
          webkit-playsinline="true"
        >
          <source
            src="https://res.cloudinary.com/dreommqtd/video/upload/v1739170818/Rdc-why-you-need-us-bg_1_pgrizs.mp4"
            type="video/mp4"
          />
        </video>

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent to-black md:bg-gradient-to-r  md:from-transparent md:to-black md:inset-0"></div>
      </div>

      {/* Content Section */}
      <div className="relative z-1 flex items-end justify-end min-h-screen p-0 bg-[black]/30 md:bg-[black]/30">
        <div className="">
          <h1 className="bg-transparent text-[47px] font-bold text-yellow-400 mb-0 p-8 ">
            WHY DO YOU
            <br /> NEED US?
          </h1>
          <p className=" lg:bg-[white] text-[white] md:text-[white] lg:text-[black] p-8 rounded-t-3xl rounded-l-3xl max-w-lg w-full text-lg leading-relaxed">
            In today’s dynamic construction landscape,{" "}
            <span className="font-semibold">
              cost overruns, quality inconsistencies, material inefficiencies,
              and waterproofing failures
            </span>{" "}
            are some of the most pressing challenges. While architects design
            and engineers plan, ensuring flawless execution on-site requires{" "}
            <span className="font-semibold">specialized expertise</span> that
            goes beyond traditional roles.{" "}
            <span className="font-bold">That’s where we come in.</span>
          </p>
        </div>
      </div>
    </div>
  );
}
