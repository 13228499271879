import React from "react";
import QuoteIcon from "../../../../assets/Seges-Icons/quotes-seges-theme-v2.svg"; // Adjust path based on your project structure

const SlcHowWeDoItPhone = () => {
  return (
    <div className="relative min-h-screen bg-cover bg-center p-8 md:p-16 text-white ">
      <video
        className="absolute inset-0 w-full h-full object-cover opacity-70"
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        webkit-playsinline="true"
      >
        <source
          src="https://res.cloudinary.com/dreommqtd/video/upload/v1739171239/slc-How-We-Do-It_1_zkjfm0.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>{" "}
      {/* Dark overlay for better contrast */}
      <div className="relative z-10 flex flex-col md:flex-row items-center justify-between min-h-screen rounded-lg border-2 border-yellow-400   ">
        {/* Left Section */}
        <div className="md:w-1/2  bg-opacity-70 p-8  text-left space-y-4 ">
          <h1 className="text-4xl font-bold">How We Do It.</h1>

          <div>
            <h3 className="font-bold text-yellow-400">
              End-to-End Supervision
            </h3>
            <p>
              We work as the bridge between you and your contractors, ensuring
              seamless execution.
            </p>
          </div>

          <div>
            <h3 className="font-bold text-yellow-400">
              Cost-Efficient Planning
            </h3>
            <p>Identifying ways to cut costs without cutting corners.</p>
          </div>

          <div>
            <h3 className="font-bold text-yellow-400">
              On-Site Quality Control
            </h3>
            <p>
              Ensuring every detail—from foundation to finishing—is built to
              perfection.
            </p>
          </div>

          <div>
            <h3 className="font-bold text-yellow-400">Material Expertise</h3>
            <p>
              From structural elements to finishing touches, we help you choose
              the best materials for both aesthetics and durability.
            </p>
          </div>
          {/* Right Section with Quote */}
          <div className="relative w-full md:w-1/3 bg-transparent bg-opacity-50 pt-1 pl-1 rounded-lg shadow-lg flex flex-col items-start border-l-4  ">
            {/* Custom Short Top Border Using `::before` */}
            <div className="absolute top-0 left-0 w-4 h-[4px] bg-white rounded-lg"></div>

            <div className="absolute top-0 right-0 w-[82%] h-[4px] bg-white rounded-lg"></div>

            <div className="absolute -top-4 left-3 flex space-x-1">
              <img src={QuoteIcon} alt="Quote Icon" className="w-10 h-10 " />
            </div>

            {/* Quote Text */}
            <p className="text-white mt-4 text-lg"> At Seges,</p>
            <p className="text-white text-lg font-bold">
              we transform homebuilding from stressful to seamless, ensuring
              your private residence is built to perfection, just as you
              envisioned.{" "}
            </p>
          </div>

          {/* <button className="mt-4 px-6 py-2 border border-white rounded-full hover:bg-yellow-400 hover:text-black transition duration-300">
            Discover Solutions
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SlcHowWeDoItPhone;
