import React from "react";
import { Link } from "react-router-dom";
import { MessageCircle } from "lucide-react"; // Icon for better UI
import "./DiscoverSolution.scss"; // Styling file

const DiscoverSolutionNew = () => {
  return (
    <div className="discover-solution-btn">
      <Link to="/ContactUs">
        <button className="discover-button">
          {/* <MessageCircle size={20} className="icon" /> */}
          Discover Solutions
        </button>
      </Link>
    </div>
  );
};

export default DiscoverSolutionNew;
