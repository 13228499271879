import React, { useState } from "react";
import mountainImage from "../../assets/whyAndRoleBg.jpg"; // Adjust the path as needed
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
const WhyAndRole = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center bg-white text-gray-800 ">
      {/* Section 1 */}
      <div className="max-w-6xl w-full grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="px-6 text-left">
          <h2 className="text-3xl font-bold mb-4">Why Choose Seges?</h2>
          <p className="text-base leading-relaxed mb-4">
            Whether you’re a{" "}
            <strong>developer delivering large-scale projects</strong> or a
            homeowner <strong>crafting a private retreat</strong>, Seges brings
            the expertise, precision, and dedication needed to ensure your
            <strong>
              {" "}
              construction journey is smooth, cost-effective, and executed
              flawlessly
            </strong>
            .
          </p>
          <p className="text-base">
            Let’s{" "}
            <strong>
              build smarter, live better, and create with confidence
            </strong>
            .
          </p>
          <div className="flex lg:flex-row sm:flex-col md:flex-col  justify-left items-left gap-3 mt-3">
            {/* Phone */}
            <a
              href="tel:+91 911 2477 911"
              className="flex items-center text-lg text-yellow-500 hover:underline"
            >
              {typeof window !== "undefined" &&
              window.innerWidth < 768 &&
              window.innerWidth < 640 ? (
                <LocalPhoneIcon className="w-5 h-5 mr-2 text-black" />
              ) : (
                <>
                  <LocalPhoneIcon className="w-5 h-5 mr-2 text-black" />
                  +91 911 2477 911
                </>
              )}
            </a>

            {/* Email */}
            <a
              href="mailto:expert@seges.earth"
              className="flex items-center text-lg text-yellow-500 hover:underline"
            >
              {typeof window !== "undefined" &&
              window.innerWidth < 768 &&
              window.innerWidth < 640 ? (
                <EmailIcon className="w-5 h-5 mr-2 text-black" />
              ) : (
                <>
                  <EmailIcon className="w-5 h-5 mr-2 text-black" />
                  expert@seges.earth
                </>
              )}
            </a>

            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-lg text-blue-500 hover:underline"
            >
              <LinkedInIcon className="w-5 h-5 mr-2" />
            </a>
          </div>
        </div>

        <div className="px-6 text-right">
          <h2 className="text-3xl font-bold mb-4">Our Role as the Bridge</h2>
          <p className="text-base leading-relaxed mb-4">
            At Seges, we see ourselves as the{" "}
            <strong>link between architects, engineers, and clients</strong>,
            ensuring that every project runs seamlessly while upholding
            sustainability principles:
          </p>
          <p className="text-base">
            1. <strong>Aligning Vision with Execution:</strong> We foster
            camaraderie among stakeholders, ensuring that{" "}
            <strong>architects’ designs</strong>,{" "}
            <strong>engineers’ plans</strong>, and
            <strong> client expectations are fully aligned</strong> and
            collaboratively executed.
          </p>
          {showMore && (
            <>
              <p className="text-base mt-4">
                2. <strong>Streamlining Processes:</strong> By simplifying
                workflows and ensuring clear communication, we help eliminate
                delays, reduce conflicts, and achieve project goals efficiently.
              </p>
              <p className="text-base mt-4">
                3. <strong>Elevating Capabilities:</strong> We provide technical
                insights and training for architects and engineers to integrate
                sustainable practices into their work, ensuring that every
                project is a benchmark for responsible construction.
              </p>
            </>
          )}
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-gray-600 underline mt-2 inline-block"
          >
            {showMore ? "Read less" : "Read more"}
          </button>
        </div>
      </div>

      {/* Section 2 - Image with Button */}
      <div className="relative w-full h-60 max-w-6xl rounded-xl overflow-hidden">
        <div className="bg-yellow-400 rounded-t-xl">
          <img
            src={mountainImage}
            alt="Mountains"
            className="w-full h-60 object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyAndRole;
