import React from "react";
import SectionWhat from "@/components/SectionWhat/SectionWhat.jsx";
import SectionWhy from "@/components/SectionWhy/SectionWhy.jsx";
import SectionHow from "@/components/SectionHow/SectionHow.jsx";
import SectionWho from "@/components/SectionWho/SectionWho.jsx";
import SectionWhen from "@/components/SectionWhen/SectionWhen.jsx";
import SectionWhere from "@/components/SectionWhere/SectionWhere.jsx";
import ScrollToTop from "../../scroll-top/ScrollToTop.js";
import DiscoverSolution from "../../scroll-top/DiscoverSolution.js";
import WhyAndRole from "../../whyAndRole/whyAndRole.js";
import { useNavigate } from "react-router-dom";
import DiscoverSolutionNew from "../../../DiscoverSolution.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const SustainableConstruction = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Back Button */}
      <div className="back-btn">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </button>
      </div>
      <div className="py-14">
        <SectionWhat />
      </div>
      <div className="py-8">
        <SectionWhy />
      </div>
      <div className="py-8">
        <SectionHow />
      </div>
      <div className="py-8">
        <SectionWho />
      </div>
      <div className="py-8">
        <SectionWhen />
      </div>
      <div className="py-8">
        <SectionWhere />
      </div>
      <div className="py-8">
        <ScrollToTop />
      </div>
      <div className="py-0">
        <DiscoverSolution />
      </div>{" "}
      <DiscoverSolutionNew />
    </div>
  );
};

export default SustainableConstruction;
