import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/home-page-arrow.png";
import backgroundImageMobile from "../../assets/home-page-v2-mobile.jpg";
import backgroundImageRight from "../../assets/right-side-bg.jpg";

const HomePageV2 = () => {
  const navigate = useNavigate();
  const [showMoreLeft, setshowMoreLeft] = useState(false);
  const [showMoreRight, setshowMoreRight] = useState(false);

  return (
    <div
      className="flex flex-col md:flex-row w-full h-screen relative overflow-hidden bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage:
          typeof window !== "undefined" && window.innerWidth > 768
            ? `url(${backgroundImage})`
            : `url(${backgroundImageMobile})`,
      }}
    >
      {/* Left Section - Clickable */}
      <div
        onClick={() => navigate("/Sustainable-Construction")}
        className="w-full md:w-1/2 h-1/2 md:h-full flex flex-col justify-center p-4 xl:mt-[10px] md:mt-0 sm:mt-0 md:p-24 transition-transform duration-300 hover:scale-105 hover:shadow-xl cursor-pointer lg:bg-white/0 
        md:bg-white/0 sm:bg-white/0 md:hover:scale-105 sm:hover:scale-105"
      >
        <h1 className="text-xl md:text-3xl font-semibold mb-2 md:mb-4 text-center sm:text-center md:text-center lg:text-right">
          The Intelligent Future of
          <br />
          <span className="bg-yellow-400 px-2 ml-1 md:ml-2 rounded-lg lg:text-white lg:text-right sm:text-center md:text-center">
            Sustainable Construction
          </span>
        </h1>
        <p className="text-gray-600 mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-right">
          We are crafting a transformative solution that redefines how
          construction meets the demands of a changing world.
        </p>
        {!showMoreLeft ? (
          <p
            className="text-black font-medium mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-right cursor-pointer"
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigation
              setshowMoreLeft(true);
            }}
          >
            Read more
          </p>
        ) : (
          <div
            className="text-gray-700 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-right"
            onClick={(e) => e.stopPropagation()} // Prevent navigation when interacting with expanded content
          >
            <p>
              At the intersection of ESG values and BIM precision lies an
              innovative approach that will challenge conventions and inspire
              new possibilities.
            </p>
            <p>
              By weaving sustainability, governance, and advanced methodologies
              into the fabric of construction, this solution is set to pave the
              way for a smarter, more intelligent and responsible future.
            </p>
            <p
              className="text-black font-medium mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-right cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent navigation
                setshowMoreLeft(false);
              }}
            >
              Read less
            </p>
          </div>
        )}
      </div>

      {/* Right Section - Clickable */}
      <div
        onClick={() => navigate("/Construction-Challenges")}
        className="xl:mt-[+50px] md:mt-0 sm:mt-0 w-full md:w-1/2 h-1/2 md:h-full relative flex justify-left items-center lg:text-black transition-transform duration-300 hover:scale-105 hover:shadow-xl p-4 md:p-10 cursor-pointer bg-cover bg-no-repeat bg-center "
      >
        {/* Overlay for better text readability on small screens */}

        <div className="relative z-10 text-center p-2 md:p-6 md:mt-10 sm:mt-10">
          <h1 className="text-xl md:text-3xl font-semibold mb-2 md:mb-4 lg:text-white  md:text-black sm:text-black text-center sm:text-center md:text-center lg:text-left">
            The Sustainable <br />
            <span className="bg-yellow-400 px-2 ml-0 md:ml-0 rounded-lg lg:text-black lg:text-left sm:text-center md:text-center">
              Construction Concierge
            </span>
          </h1>
          <p className="lg:text-white  md:text-black sm:text-black mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-left">
            Seamless execution, cost-effective solutions, and sustainable
            excellence—tailored for developers, builders, and luxury homeowners.
          </p>

          {!showMoreRight ? (
            <p
              className="text-white md:text-black font-medium mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-left cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent navigation
                setshowMoreRight(true);
              }}
            >
              Read more
            </p>
          ) : (
            <div
              className="lg:text-white  md:text-black sm:text-black text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-left inset-0 bg-white/40 md:bg-transparent sm:bg-white/40"
              onClick={(e) => e.stopPropagation()} // Prevent navigation when interacting with expanded content
            >
              {/* <div className="absolute inset-0 bg-black/50 md:bg-transparent sm:bg-black/10"></div> */}

              <p>
                At Seges, we don’t just support construction; we redefine it. We
                act as the bridge between architects, engineers, and
                clients—filling critical gaps in knowledge, execution, and
                sustainability.
              </p>
              <p>
                Whether it’s building responsibly or ensuring effortless living,
                our bespoke solutions ensure precision, efficiency, and value at
                every stage.
              </p>
              <p
                className="text-white md:text-black font-medium mb-2 md:mb-4 text-sm md:text-base px-4 md:px-0 text-center sm:text-center md:text-center lg:text-left cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation
                  setshowMoreRight(false);
                }}
              >
                Read less
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePageV2;
