import React from "react";
import SlcWhyDoYouNeedUs from "./slc-why-page.js";
import SlcWhatWeDo from "./slc-what-we-do.js";
import SlcHowWeDoIt from "./slc-how-page.js";
import WhyAndRole from "../../../whyAndRole/whyAndRole.js";
import { useNavigate } from "react-router-dom";
import "../Responsible-Development-Concierge/rdc.css";
import ContactUs from "../../../ContactUsPage.jsx";
import StickyFooter from "../../stickyFooter.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SlcHomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="back-btn">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </button>
      </div>
      <SlcWhyDoYouNeedUs />
      <SlcWhatWeDo />
      <SlcHowWeDoIt />
      {/* <SlcHowWeDoIt /> */}
      <div className="py-10">
        <WhyAndRole />
      </div>
      {/* <div className="py-0">
        <StickyFooter />
      </div> */}
    </>
  );
};

export default SlcHomePage;
