import React, { useState, useEffect } from "react";
import RdcHowWeDoItPhone from "./rdc-HowWeDoItPhone.js";
import RdcHowWeDoItLaptop from "./rdc-howWeDoItLaptop.js";

export default function RdcHowWeDoIt() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update when screen resizes
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{isMobile ? <RdcHowWeDoItPhone /> : <RdcHowWeDoItLaptop />}</>;
}
