import React from "react";
import RDcWhyDoYouNeedUs from "../../../../assets/slc-what-we-do.jpg";

export default function SlcWhatWeDo() {
  return (
    // <div
    //   className="flex flex-col md:flex-row items-center justify-between min-h-screen bg-white p-8 md:p-16"
    //   style={{
    //     backgroundImage: `url(${RDcWhyDoYouNeedUs})`,
    //     backgroundSize: "contain",
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    //   }}
    // >

    <div className="flex flex-col md:flex-row items-center h-screen lg:justify-between sm:justify-between md:justify-between  bg-white p-6 md:p-16 relative ">
      {/* Background Image for Mobile (Dimmed) */}
      <div
        className="absolute inset-1 bg-cover bg-center bg-no-repeat md:bg-none opacity-30 md:opacity-100"
        style={{ backgroundImage: `url(${RDcWhyDoYouNeedUs})` }}
      ></div>{" "}
      {/* Left Section */}
      <div className="relative md:w-1/3 lg:text-left md:text-left sm:text-left space-y-4 z-10 ">
        <h1 className="text-3xl md:text-4xl font-bold text-black">
          What do we do?
        </h1>
        <p className="text-black sm:text-left md:text-left md:text-base ">
          We take the stress out of homebuilding by acting{" "}
          <strong>as your trusted guide </strong>
        </p>
        {/* <button className="mt-4 px-6 py-2 border border-black rounded-full hover:bg-black hover:text-black transition duration-300">
          Discover Solutions
        </button> */}
      </div>
      {/* Right Section */}
      <div className="relative w-full md:w-1/3 text-left lg:space-y-6 md:space-y-14 sm:space-y-14 z-10 py-10 ">
        <div>
          <h3 className="font-bold text-black text-lg">
            Your investment is optimized
          </h3>
          <p className="text-black text-sm md:text-base">
            Avoid unnecessary costs while maintaining superior quality.
          </p>
        </div>

        <div>
          <h3 className="font-bold text-black">
            Your materials match your vision
          </h3>
          <p className="text-black">
            Sourcing high-end, sustainable materials tailored to your aesthetic
            preferences.
          </p>
        </div>

        <div>
          <h3 className="font-bold text-black">
            Your construction meets perfection
          </h3>
          <p className="text-black">
            Overseeing workmanship to guarantee impeccable execution.
          </p>
        </div>

        <div>
          <h3 className="font-bold text-black">
            Your experience is effortless
          </h3>
          <p className="text-black">
            From start to finish, we handle complexities so you don’t have to.
          </p>
        </div>
      </div>
    </div>
  );
}
