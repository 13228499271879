import React from "react";
import QuoteIcon from "../../../../assets/Seges-Icons/quotes-seges-theme-v2.svg"; // Adjust path based on your project structure

const RdcHowWeDoItPhone = () => {
  return (
    <div className="relative max-h-screen bg-cover bg-center p-4 lg:p-8 sm:p-6 md:p-8 text-white">
      <video
        className="absolute inset-0 w-full h-full object-cover opacity-70"
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        webkit-playsinline="true"
      >
        <source
          src="https://res.cloudinary.com/dreommqtd/video/upload/v1739171336/RDC-how-we-do-it_1_nnbz9f.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      <div className="relative z-10 flex flex-col md:flex-row items-center justify-between rounded-lg border-2 border-yellow-400 p-4 md:p-8">
        {/* Left Section */}
        <div className="md:w-1/2 w-full bg-opacity-70 text-left space-y-4">
          <h1 className="text-3xl sm:text-4xl font-bold">How We Do It.</h1>
          <div>
            <h3 className="font-bold text-yellow-400">
              Engagement at Any Stage
            </h3>
            <p>
              Whether you're planning, midway through construction, or
              addressing issues post-completion, we integrate seamlessly to
              resolve challenges.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-yellow-400">
              Hands-On Site Oversight
            </h3>
            <p>
              Unlike consultants who offer theoretical solutions, we provide
              on-ground supervision to ensure plans are executed flawlessly.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-yellow-400">
              Collaborative Execution
            </h3>
            <p>
              Working alongside your architects and engineers to bridge gaps,
              enhance precision, and optimize processes.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-yellow-400">
              Sustainability & Longevity
            </h3>
            <p>
              We don’t just fix problems; we future-proof your projects with
              lasting solutions.
            </p>
          </div>

          {/* Quote Section - Positioned Correctly */}
          <div className="relative w-full md:w-1/3 bg-transparent bg-opacity-50 pt-1 pl-1 rounded-lg shadow-lg flex flex-col items-start border-l-4  ">
            {/* Short Top Border */}
            <div className="absolute top-0 left-0 w-2 h-[4px] bg-white rounded-lg"></div>
            <div className="absolute top-0 right-0 w-[82%] h-[4px] bg-white rounded-lg"></div>

            {/* Yellow Quote Mark */}
            <div className="absolute -top-4 left-3 flex space-x-1">
              <img src={QuoteIcon} alt="Quote Icon" className="w-10 h-10 " />
            </div>

            {/* Quote Text */}
            <p className="text-white mt-1 text-lg sm:text-xl">
              At Seges, we help builders
            </p>
            <p className="text-white text-lg sm:text-xl font-bold">
              construct smarter, build stronger,{" "}
              <br className="hidden md:block" /> and deliver better.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RdcHowWeDoItPhone;
