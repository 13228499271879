// src/index.js
import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import Loader from "./Loader";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  // const [countdown, setCountdown] = useState(12); // Initialize countdown to 12 seconds

  useEffect(() => {
    // Set fade-out state after 27 seconds (3 seconds before switching)
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
    }, 25000); // 25 seconds

    // Set loading state to false after 30 seconds
    const loadTimer = setTimeout(() => {
      setLoading(false);
    }, 27000); // 27 seconds
    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(loadTimer);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader fadeOut={fadeOut} /> // Pass countdown to Loader
      ) : (
        <App />
      )}
    </>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Index />
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
