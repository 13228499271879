import React, { useState } from "react";
import RDcWhyDoYouNeedUs from "../assets/contact-us.jpg";

function ContactUs() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFormSubmitted(true);
          form.reset();

          setTimeout(() => {
            setFormSubmitted(false);
          }, 2000);
        } else {
          alert("There was a problem submitting your form.");
        }
      })
      .catch(() => {
        alert("There was a problem submitting your form.");
      });
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center p-6 bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${RDcWhyDoYouNeedUs})` }}
    >
      <section className="w-full max-w-5xl grid grid-cols-1 md:grid-cols-2 bg-opacity-90 p-10 rounded-xl shadow-2xl border border-yellow-500 gap-8 inset-0 bg-cover bg-center bg-no-repeat md:bg-none bg-black">
        {/* Left Partition: Contact Information */}
        <div className="flex flex-col justify-center space-y-6 text-center md:text-left">
          <h1 className="text-4xl font-extrabold text-yellow-500">
            Get in Touch
          </h1>
          <p className="text-lg">
            We'd love to hear from you! Let’s discuss how we can collaborate.
          </p>
          <div>
            <h3 className="text-2xl font-bold text-yellow-500">
              Contact Information
            </h3>
            <p className="mt-4 text-lg">
              📞{" "}
              <a
                href="tel:+919112477911"
                className="text-yellow-500 hover:underline"
              >
                +91 911 2477 911
              </a>
            </p>
            <p className="text-lg">
              📧{" "}
              <a
                href="mailto:expert@seges.earth"
                className="text-yellow-500 hover:underline"
              >
                expert@seges.earth
              </a>
            </p>
            <p className="text-lg">
              🔗{" "}
              <a
                href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-500 hover:underline"
              >
                LinkedIn Profile
              </a>
            </p>
          </div>
        </div>

        {/* Right Partition: Contact Form */}
        <form
          action="https://formspree.io/f/xpwajdqz"
          method="POST"
          className="flex flex-col space-y-4 "
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required
            className=" bg-gray-800 text-white rounded-lg p-5 m-5 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />

          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required
            className="p-5 bg-gray-800 text-white rounded-lg  m-5 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />

          <input
            type="text"
            id="company"
            name="company"
            placeholder="Company"
            required
            className="p-5 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />

          <textarea
            id="message"
            name="message"
            rows="4"
            placeholder="Message"
            required
            className="p-5 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
          ></textarea>

          <button
            type="submit"
            className="bg-white text-black font-bold py-3 px-6 rounded-lg hover:bg-yellow-400 hover:text-black transition"
          >
            {formSubmitted ? "✔ Sent" : "Send Message"}
          </button>
        </form>
      </section>
    </div>
  );
}

export default ContactUs;
